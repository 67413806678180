window.$ = require('jquery');
window.jQuery = window.$;

$(function() {
	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if ( scroll >= 0.001 * $(window).height() )
		{
			$('.navigation__links').addClass('hide');
			$('.navigation__hamburger').addClass('visible');
		}
		else
		{
			$('.navigation__links').removeClass('hide');
			$('.navigation__hamburger').removeClass('visible');
			$('.sidebar-navigation').removeClass('visible');
			$('.navigation__hamburger').removeClass('navigation__hamburger--close');
		}
	});

	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if ( scroll >= 0.001 * $(window).height() )
		{
			setTimeout(() => {
				$('.sub-menu').fadeOut(200);
			}, 200);

		}
		else
		{
			setTimeout(() => {
				$('.sub-menu').fadeIn(200);
			}, 200);
		}
	});


	$('.js-open-menu').on('click', function(){
		$('.sidebar-navigation').toggleClass('visible');
		$(this).toggleClass('navigation__hamburger--close');
	});

	$('a[href*=\\#]').on('click', function(event){
		event.preventDefault();
		$('html,body').animate({scrollTop:$(this.hash).offset().top}, 500);
	});

});
window.$ = require('jquery');
window.jQuery = window.$;


$(function() {

	$('.js-get-work-posts').on('click', function(){
		$('.js-get-work-posts').removeClass('active');
		getWorkPosts($(this).data('category'));
		$('.article-listing__grid').animate({opacity:0});
		$(this).addClass('active');
	});

	function getWorkPosts(id){
		$.ajax({
			type: "POST",
			url: ajaxUrl,
			dataType:'html',
			data: {
				action: 'filter_work_posts', id: id
			},
			success: function (data) {
				$('.article-listing__grid').html(data);

				setTimeout(() => {
					$(".article-listing__grid .article-listing__grid-item").each(function(i) {

						setTimeout(() => {
							$(this).fadeTo('200', 1);
							$(this).css('transform', 'scale(1)')
						}, 100*i);
					});
				}, 300);

				$('.article-listing__grid').animate({opacity:1});
			}
		});
	}
});
window.$ = require('jquery');
window.jQuery = window.$;


$(function() {

	var slides = $('.slideshow .slide');
	var slidesAmount = $('.slide').length;
	var count = 1;

	var sliderautoplay = setInterval(autoPlay, 4000);

	$('.js-slide-next').on('click', function(){
		nextSlide();
		clearInterval(sliderautoplay);
	});

	$('.js-slide-prev').on('click', function(){
		prevSlide();
		clearInterval(sliderautoplay);
	});

	function autoPlay(){
		nextSlide();
	}

	function nextSlide(){
		var currentSlideIndex = $('.slideshow .slide.visible').index();
		var amountSlides = $('.slideshow .slide').length-1;

		if(currentSlideIndex >= amountSlides){
			$('.slide').eq(0).addClass('visible');
		}

		$('.slide').eq(currentSlideIndex).removeClass('visible');
		$('.slide').eq(currentSlideIndex+1).addClass('visible');

		count++;

		if(count > slidesAmount){
			count = 1;
		}

		$('.js-currentslide').html(count);
	}

	function prevSlide(){
		var currentSlideIndex = $('.slideshow .slide.visible').index();
		var amountSlides = $('.slideshow .slide').length;

		$('.slide').eq(currentSlideIndex).removeClass('visible');
		$('.slide').eq(currentSlideIndex-1).addClass('visible');

		count--;

		if(count == 0){
			count = amountSlides;
		}

		$('.js-currentslide').html(count);
	}


	$('.js-slidesamount').html($('.slide').length);
});
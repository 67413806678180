window.$ = require('jquery');
window.jQuery = window.$;


$(function() {
	var urlParams = new URLSearchParams(window.location.search);

	$('.js-filter-show-all').on('click', function(){
		$('.js-filter-button').removeClass('active');
		getPosts(0);
		$(this).addClass('active');
		$('.sub-cat').html('');
	});

	$('.js-filter-show-all-work').on('click', function(){
		$('.js-filter-button').removeClass('active');
		getPosts(29);
		$(this).addClass('active');
		$('.sub-cat').html('');
	});

	$('.js-filter-button').on('click', function(){
		if($(this).hasClass('active'))
		{
			$(this).removeClass('active')
			$('.sub-cat').removeClass('visible');
			//$('.sub-cat').html('');
		} else {
			openFilter($(this))
		}
	});

	$(".sub-cat").on("click", ".js-subcat-button", function(){
		filterOnSubcat($(this))
	});

	$('.js-filter-load-more').on('click', function(){
		$(this).closest('.article-listing__grid').find('.article-listing__grid-page-break').children().unwrap();
		$(this).closest('.article-listing__grid-load-more').remove();
	});

	if(urlParams.get('filter') && urlParams.get('category')){
		var filterButton = $('.js-filter-button[data-category="'+$.escapeSelector(urlParams.get('filter'))+'"]');
		
		if(filterButton.length){
			openFilter(filterButton, function(){
				var subcatButton = $('.js-subcat-button[data-slug="'+$.escapeSelector(urlParams.get('category'))+'"]');
		
				filterOnSubcat(subcatButton);
			});
		}
	}

	function openFilter(filterButton, cb){
		$('.js-filter-button').removeClass('active');
		getChildCats(filterButton.data('category'), cb);
		filterButton.addClass('active');
		$('.js-filter-show-all').removeClass('active');
		$('.js-filter-show-all-work').removeClass('active');
	}

	function filterOnSubcat(subcatButton){
		$('.js-subcat-button').removeClass('active');
		getPosts(subcatButton.data('category'));
		$('.article-listing__grid').animate({opacity:0});
		subcatButton.addClass('active');
	}

	function getPosts(posttype){
		$.ajax({
			type: "POST",
			url: ajaxUrl,
			dataType:'html',
			data: {
				action: 'filter_posts', posttype: posttype
			},
			success: function (data) {
				$('.article-listing__grid').html(data);

				setTimeout(() => {
					$(".article-listing__grid .article-listing__grid-item").each(function(i) {

						setTimeout(() => {
							$(this).fadeTo('200', 1);
							$(this).css('transform', 'scale(1)')
						}, 100*i);
					});
				}, 300);

				$('.article-listing__grid').animate({opacity:1});
			}
		});
	}

	function getChildCats(id, cb) {
		$.ajax({
			type: "POST",
			url: ajaxUrl,
			dataType:'html',
			data: {
				action: 'get_subcat', id: id
			},
			success: function (data) {
				if(data.length > 39){
					$('.sub-cat').addClass('visible');
					$('.sub-cat').html(data);
				}else{
					$('.sub-cat').html('');
				}

				if(cb) cb();
			}
		});
	}
});
window.$ = require('jquery');
window.jQuery = window.$;


$(function() {

	$('.team__member').on('click', function(){
		getCoWorker($(this).data('id'));
		$('body').css('overflow', 'hidden');
	});

	$(".team__memberinfo").on("click", ".js-close-coworker", function(){
		$('.overlay').removeClass('visible');
		$('.team__memberinfo').removeClass('visible');
		$('body').css('overflow', 'auto');
	});

	$('.overlay').on('click', function(){
		$(this).removeClass('visible');
		$('.team__memberinfo').removeClass('visible');
		$('body').css('overflow', 'auto');
	});

	function getCoWorker(id){
		$.ajax({
			type: "POST",
			url: ajaxUrl,
			dataType:'html',
			data: {
				action: 'get_coworker', id:id
			},
			success: function (data) {
				$('.overlay').addClass('visible');
				$('.team__memberinfo').html(data);
				$('.team__memberinfo').addClass('visible');
			}
		});
	}
});

$(document).keyup(function(e) {
	if (e.keyCode === 27){
		$('.overlay').removeClass('visible');
		$('.team__memberinfo').removeClass('visible');
		$('body').css('overflow', 'auto');
	}
  });
window.$ = require('jquery');
window.jQuery = window.$;

$(function() {
	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if ( scroll >= 0.001 * $(window).height() )
		{
			$('.chat-bot').addClass('visible');
		}
	});
});


$.fn.isInViewport = function() {
	var elementTop = $(this).offset().top;
	var elementBottom = elementTop + $(this).outerHeight();

	var viewportTop = $(window).scrollTop();
	var viewportBottom = viewportTop + $(window).height();

	return elementBottom > viewportTop && elementTop < viewportBottom;
};


$(window).on('resize scroll', function() {
	if ( $('.contact-block').length ) {
		if ($('.contact-block').isInViewport()) {
			$('.chat-bot').addClass('contact-block-visible');
		} else {
			$('.chat-bot').removeClass('contact-block-visible');
		}
	} else {
		$('.chat-bot').removeClass('contact-block-visible');
	}
});